import React from 'react';
import './App.scss';
import headerImage from './images/header.jpg';

function App() {
  return (
    <div className="App">
      <div className="container">
        <header className="App-header" >
          <h1>SBR Creative Media</h1>
          <p>SBR Creative Media advisors/consultants to Triple A, Adult Rock, Alternative, Modern Rock, Classic Rock radio stations in U.S. and Canada.</p>
        </header>

        <div className="jumbotron header-image">
          <img alt="SBRCreative header" src={ headerImage } className="img-fluid mx-auto" />
        </div>

        <div className="content">
          <h1>Branding - Programming - Streaming</h1>
          <p className="text-center">SBR Creative Media is on the leading edge keeping radio stations connected with listeners.</p>
          <br />
          <p><b>SBR Creative Media</b> advises radio stations in the USA and Canada in Triple A, Adult Rock, Alternative, Modern Rock and Classic Rock formats. We are Adult Rock experts with decades of radio broadcasting experience.</p>
          <p>We advise radio stations on overall branding, programming, music selection and scheduling, music testing, promotions, marketing, listener research, ratings analysis, talent development, and the constantly changing media and technology choices for consumers.</p>
        </div>
      </div>

      <footer className="footer mt-auto py-3">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              John Bradley<br />
              john @ SBRcreative.com
            </div>
            <div className="col-sm-3">
              Dave Rahn<br />
              dave.rahn @ SBRcreative.com
            </div>
            <div className="col-sm-3">
              Jason Lucero<br />
              jason @ SBRcreative.com
            </div>
            <div className="col-sm-3">
              <a href="https://www.customchannels.net">Click here for Custom Channels by SBR</a><br />
              Copyright { new Date().getFullYear() } SBR Creative Media
            </div>
          </div>
        </div>
      </footer>

    </div>
  );
}

export default App;
